/**
 * Created by preference on 2020/07/01
 */

import * as API from '@/api/index'
export default {
  /**  52.1.项目存量列表 */
  treatGoodsProjectAccount: params => {
    return API.POST('api/treatGoodsAccount/projectAccount', params)
  },
  /** 52.2.产品存量列表 */
  treatGoodsProductAccount: params => {
    return API.POST('api/treatGoodsAccount/productAccount', params)
  },
  /**  52.3.储值卡存量列表 */
  treatGoodsSavingCardAccount: params => {
    return API.POST('api/treatGoodsAccount/savingCardAccount', params)
  },
  /**  52.4.储值卡消耗适用项目 */
  treatGoodsSavingCardAccountProject: params => {
    return API.POST('api/treatGoodsAccount/savingCardAccountProject', params)
  },
  /**  52.5.通用次卡存量列表 */
  treatGoodsGeneralCardAccount: params => {
    return API.POST('api/treatGoodsAccount/generalCardAccount', params)
  },
  /**  52.6.通用次卡消耗适用项目 */
  treatGoodsGeneralCardAccountProject: params => {
    return API.POST('api/treatGoodsAccount/generalCardAccountProject', params)
  },
  /**  52.7.时效卡存量列表*/
  treatGoodsTimeCardAccount: params => {
    return API.POST('api/treatGoodsAccount/timeCardAccount', params)
  },
  /**  52.8.时效卡消耗适用项目 */
  treatGoodsTimeCardAccountProject: params => {
    return API.POST('api/treatGoodsAccount/timeCardAccountProject', params)
  },
  /**  52.9.套餐卡存量列表 */
  treatGoodsPackageCardAccount: params => {
    return API.POST('api/treatGoodsAccount/packageCardAccount', params)
  },
  /**  52.10.套餐卡存量明细 */
  treatGoodsPackageCardAccountDetails: params => {
    return API.POST('api/treatGoodsAccount/packageCardAccountDetails', params)
  },
  /**  52.11.获取项目消耗经手人 */
  treatGoodsProjectHandler: params => {
    return API.POST('api/treatHandler/projectHandler', params)
  },
  /**  52.12.获取产品消耗经手人 */
  treatGoodsProductHandler: params => {
    return API.POST('api/treatHandler/productHandler', params)
  },
  /**  52.13.获取储值卡消耗经手人 */
  treatGoodsSavingCardHandler: params => {
    return API.POST('api/treatHandler/savingCardHandler', params)
  },
  /**  52.14.消耗结账 */
  treatBillCreate: params => {
    return API.POST('api/treatBill/create', params)
  },
}
